import React from 'react'
import Head from 'next/head'
import {
  useGlobalData,
  getNumberOfActiveFilters,
  getFullUrl,
} from '../../utils'

export default function Metadata() {
  const { pageData, params = {} } = useGlobalData()
  const { type } = pageData

  const aggregations = pageData.data.product.aggregations

  const title = getPageTitle({ type, page: pageData.data.self })
  const robotsContent = getRobotsContent({ aggregations, queryParams: params })

  const pageDataSelf = pageData?.data?.self || {}
  const { meta_description, meta_keywords, url } = pageDataSelf
  const { fullUrl } = getFullUrl(url?.[0])
  return (
    <Head>
      {title && <title>{title}</title>}

      <meta key="robots" name="robots" content={robotsContent} />
      {meta_description && (
        <meta name="description" content={meta_description} />
      )}
      {meta_keywords && <meta name="keywords" content={meta_keywords} />}
      <link rel="canonical" href={fullUrl} />
    </Head>
  )
}

function getPageTitle({ type, page }) {
  let title = ''

  if (type === 'category') {
    const { meta_title } = page

    title = meta_title
  } else {
    // manufacturer
    const { manufacturer_title } = page

    title = manufacturer_title
  }

  return title
}

function getRobotsContent({ aggregations = {}, queryParams = {} }) {
  // const { count = 50, offset = 0, makairaFilter: activeFilters } = params
  const count = queryParams.count ?? process.env.PRODUCTS_PER_PAGE
  const offset = queryParams.offset ?? 0

  const currentPageNr = offset / count + 1
  const isFirstPage = currentPageNr === 1

  const hasActiveSorting = !!queryParams.sortBy

  const activeFilters = getNumberOfActiveFilters({ aggregations })

  // do not index when filter, pagintion or sorting is active
  const isIndexable = isFirstPage && activeFilters == 0 && !hasActiveSorting

  let robotsContent = []
  if (process.env.ROBOTS_ENABLED === 'true') {
    robotsContent.push('follow')
    if (isIndexable) {
      robotsContent.push('index')
    }
  } else {
    robotsContent.push('nofollow')
    robotsContent.push('noindex')
  }

  return robotsContent.join(', ')
}
