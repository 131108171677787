import React from 'react'
import { useGlobalData } from '../../utils'
import Metadata from './Metadata'
import { ContentElements } from '../../patterns'

function Landingpage() {
  const { pageData } = useGlobalData()
  const { top, bottom } = pageData.data.config || {}

  return (
    <>
      <Metadata />
      {top && <ContentElements elements={top.elements} />}
      {bottom && <ContentElements elements={bottom.elements} />}
    </>
  )
}

export default Landingpage
