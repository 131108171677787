import React from 'react'
import {
  ContentElements,
  NotificationBanner,
  ProductDetailInformation,
} from '../../patterns'
import {
  isAboProduct,
  ListPagePricingProvider,
  useGlobalData,
  useSnippetContent,
} from '../../utils'
import Metadata from './Metadata'

function DetailPage() {
  const notificationConfig = useSnippetContent('notification_banner')
  const { pageData } = useGlobalData()
  const productData = { ...pageData.data.self }

  const calculatePercentage = (productData) => {
    const {
      priceinformation: [{ regular, special, abo_price: discountPrice }],
    } = productData
    const basePrice = regular || special
    return `${Math.round(((basePrice - discountPrice) / basePrice) * 100)}%`
  }

  let notificationProps
  let getAboPrice
  if (
    notificationConfig &&
    isAboProduct(productData) &&
    productData.availability
  ) {
    const {
      appearance,
      pdpSubscription: { message, title, ...rest },
    } = notificationConfig
    const percentage = calculatePercentage(productData)
    const regex = /\{\{\s*percentage\s*\}\}/
    notificationProps = {
      ...appearance,
      title: title.replace(regex, percentage),
      message: message.replace(regex, percentage),
      ...rest,
    }
    // Override default abo price for the delivery box and price display
    getAboPrice = ({ cc_prices: { fixxedprice_stammkunde: p } }) => p
  }

  return (
    <>
      <Metadata />
      <ListPagePricingProvider
        productData={productData}
        getAboPrice={getAboPrice}
      >
        {notificationProps && <NotificationBanner {...notificationProps} />}
        <ContentElements
          elements={pageData.data.self.contentElements?.top?.elements}
        />
        <ProductDetailInformation
          key={productData.id}
          productData={productData}
        />

        <ContentElements
          elements={pageData.data.self.contentElements?.bottom?.elements}
        />
      </ListPagePricingProvider>
    </>
  )
}

export default DetailPage
