import React from 'react'
import Metadata from './Metadata'
import ProductList from './ProductListWithProps'

function ListingPage() {
  return (
    <>
      <Metadata />
      <ProductList />
    </>
  )
}

export default ListingPage
