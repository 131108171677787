import React from 'react'
import Head from 'next/head'
import { useGlobalData, getFullUrl } from '../../utils'

export default function Metadata() {
  const { pageData } = useGlobalData()
  const pageDataSelf = pageData?.data?.self || {}
  const { meta_title, meta_description, meta_keyword, url } = pageDataSelf
  const { fullUrl } = getFullUrl(url?.[0])
  return (
    <Head>
      {meta_title && <title>{meta_title}</title>}
      {meta_description && (
        <meta name="description" content={meta_description} />
      )}
      {meta_keyword && <meta name="keywords" content={meta_keyword} />}
      {process.env.ROBOTS_ENABLED === 'true' ? (
        <meta key="robots" name="robots" content="index, follow" />
      ) : (
        <meta key="robots" name="robots" content="noindex, nofollow" />
      )}
      <link rel="canonical" href={fullUrl} />
    </Head>
  )
}
